const config = {
  content: {
    //Prod
    // cache: "https://yongo.us-lax-1.linodeobjects.com/",
    // server: "https://yongo.us-lax-1.linodeobjects.com/",

    //Sandbox
    cache: "https://sandbox-s3.ap-south-1.linodeobjects.com/",
    server: "https://sandbox-s3.ap-south-1.linodeobjects.com/",

    //Demo
    // cache: "https://yongodemo.us-lax-1.linodeobjects.com/",
    // server: "https://yongodemo.us-lax-1.linodeobjects.com/",

    //Dev
    // cache: "https://test-s3.ap-south-1.linodeobjects.com/",
    // server: "https://test-s3.ap-south-1.linodeobjects.com/",
  },
  server: {
    //Prod
    // api: "https://api.yongo.me",
    // document: "https://dl.yongo.me",
    // timeout: 72e5, // 2 hours = 120*60*1000 = 7200000 = 72e5 ms
    // video: "https://vl.yongo.me",

    //Sandbox
    api: "https://sandboxapi.yongo.live",
    document: "https://sandboxdl.yongo.live",
    timeout: 72e5, // 2 hours = 120*60*1000 = 7200000 = 72e5 ms
    video: "https://sandboxvl.yongo.live",

    //Demo
    // api: "https://demoapi.yongo.live",
    // document: "https://demodl.yongo.live",
    // timeout: 72e5, // 2 hours = 120*60*1000 = 7200000 = 72e5 ms
    // video: "https://demovl.yongo.live",

    //Dev
    // api: "http://localhost:4000",
    // document: "http://localhost:4500",
    // timeout: 9e5, // 15 minutes = 15*60*1000 = 900000 = 9e5 ms
    // video: "http://localhost:4400",
  },
  app_download: {
    android: "https://play.google.com/apps/internaltest/4701588659958649583", //internal test
    ios: "https://apps.apple.com/us/app/spider-solitaire-card-game/id395979574",
  },
  file: {
    imageTypes: ["image/png", "image/gif", "image/jpeg"],
    imageCodes: ["89-50-4e", "47-49-46", "ff-d8-ff"],
    documentTypes: [
      "application/pdf",
      "application/msword",
      "application/vnd.ms-powerpoint",
      " application/vnd.ms-excel",
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".xls",
      ".xlsx",
    ],
    documentCodes: [
      "25-50-44",
      "0-6e-1e",
      "9-8-10",
      "d-44-4f",
      "f-0-e8",
      "50-4b-3",
      "a0-46-1d",
      "cf-11-e0",
      "d0-cf-11",
      "db-a5-2d",
      "ec-a5-c1",
      "fd-ff-ff",
    ],
  },
  grace: {
    free: 13824e5, // 16 days x 24 x 60 x 60 x 1000 = 12096 x 10^5 ms
    subscription: 13824e5, // 16 days
  },
  levels: [
    "role_member",
    "role_instructor",
    "role_content",
    "role_director",
    "role_owner",
  ],
  max_block_details: 4,
  max_attachment: 10000000, //10MB
  pexels: {
    key: "LlL8aJpfr7ZmiO8IZMdexf6eRGqU7x0N8BL6cihi7p6YTKIkJLlZlf5s",
  },
  pricing: [{ plan: "free" }, { plan: "community" }, { plan: "enterprize" }],
  public_routes: [
    "/yongo",
    "/benefits",
    "/community",
    "/find_community",
    "/getyongo",
    "/privacy",
    "/purchase_agreement",
    "/terms_conditions",
  ],
  string: {
    article: 8000,
    paragraph: 2000,
    bio: 800,
    search: 500,
    email: 320,
    brief: 120,
    title: 80,
    short: 10,
  },
  thumb_grid_keys: [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Home",
    "End",
    "PageUp",
    "PageDown",
    "CTRL+Home",
    "CTRL+End",
  ],
  video: {
    resolution: 1920, //1080p 1920x1080
    max_duration: 1200, // 1200 = 20*60 = 20 minutes
    max_filesize: 750000000, //bytes
    min_duration: 12,
  },
};

export default config;
